import { Link } from "gatsby"
import React, { Component } from 'react'
import loadable from '@loadable/component'
import { Tabs, TabList, Tab, TabPanels, TabPanel } from "@reach/tabs";

import Loader from "../components/loader";

const Head = loadable(() => import("../components/head"));
const InnerLayout = loadable(() => import('../components/Innerlayout'));
const EngMetaverseTokenPage = loadable(() => import("../components/MetaverseTokenPage/english.js"));
const ArabicMetaverseTokenPage = loadable(() => import("../components/MetaverseTokenPage/arabic.js"));
const ChineseMetaverseTokenPage = loadable(() => import("../components/MetaverseTokenPage/chinese.js"));


export class MetaverseTokenPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            lang: "English",
        }
    };

    async componentDidMount() {
        let region = localStorage.getItem('region');

        if (region == 'ae') this.setState({ lang: "Arabic" });
        // else if (region == 'sa') this.setState({ lang: "Arabic" });
        else if (region == 'cn') this.setState({ lang: "China" });
        else this.setState({ lang: "English" });
    };

    render() {
        let { lang } = this.state;

        return (
            <>
                <InnerLayout header='Main' fallback={<Loader />}>
                    <Head
                        type="Website"
                        fallback={<Loader />}
                        path="/metaverse-token-development-services/"
                        title="Metaverse Token Development Company | Softtik Technologies"
                        thumbnail="https://softtik.com/images/nft-collection/about-token.webp"
                        description="Softtik Technologies is the leading metaverse token development company with years of experience. We can help you transform your dream project into reality."
                    />
                    <main>
                        <div id="wrapper">
                            <div className={`collection-page metaverse-token-page ${lang == "Arabic" && 'rtl'}`}>
                                {lang == "Arabic"
                                    ? <ArabicMetaverseTokenPage />
                                    : lang == "China"
                                        ? <ChineseMetaverseTokenPage />
                                        : <EngMetaverseTokenPage />
                                }
                            </div>
                        </div>
                    </main>
                </InnerLayout >
            </>
        )
    };
};

export default MetaverseTokenPage;